import * as React from "react"
import styled, { keyframes } from "styled-components"
import { vw } from "../../utilities/pxtovw"
import Pattern from "./pattern"
import Icon from "./icon"
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "../../utilities/context"
import GetStarted from "./get-started-btn"

const fadeInUp = keyframes`
  0%{
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  100% {
    opacity: 1;
    transform: none; 
  }
`

const Box = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 100;
  overflow: scroll;

  @media (prefers-color-scheme: light) {
    background-color: rgb(18 24 36 / 95%);
  }

  &.false {
    display: none;
  }

  &.true {
    display: block;
  }

  .xn-pattern {
    position: absolute;
    bottom: 0;
    height: ${vw(42)};
    width: ${vw(108)};
    left: 0;
    z-index: 1;
  }
`

const Wrap = styled.div`
  width: 45%;
  position: relative;
  margin: 5vw auto;
  animation: ${fadeInUp} 0.5s ease both;

  @media (max-width: 766px) {
    width: calc(100% - 3.5rem);
    margin: 2rem auto;
  }
`

const Content = styled.div`
  background-color: var(--c-blue-200);
  padding: 3vw;
  color: var(--c-blue-200);
  padding-left: 5vw;
  padding-bottom: 6vw;
  font-size: 0.875rem;
  color: var(--c-white);

  &.has-cta {
    padding-top: 6vw;

    @media (max-width: 766px) {
      padding-top: 5rem;
    }
  }

  @media (prefers-color-scheme: light) {
    background-color: var(--c-white);
    color: var(--c-blue-200);
  }

  header {
    padding-bottom: 4vw;

    div {
      display: flex;
      font-size: 0.75rem;
      padding-top: 0.5rem;
      align-items: center;

      svg {
        margin-right: 0.5rem;
        width: 0.8rem;
        height: 0.8rem;
      }

      circle,
      path {
        stroke: var(--c-mint-100);

        @media (prefers-color-scheme: light) {
          stroke: var(--c-green-200);
        }
      }
    }
  }

  h2 {
    font-size: 2rem;
  }

  h2,
  h3 {
    color: var(--c-mint-100);

    @media (prefers-color-scheme: light) {
      color: var(--c-green-200);
    }
  }

  header,
  section {
    position: relative;
    z-index: 1;
  }
  section {
    padding-bottom: 4vw;
    line-height: 1.7;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-family: var(--xn-font-body);
    font-size: 0.75rem;
    padding-bottom: 1vw;
  }

  li {
    line-height: 1.75;

    svg {
      height: 0.625rem;
      width: 0.625rem;
      margin-right: 0.75rem;

      path {
        fill: var(--c-mint-100);

        @media (prefers-color-scheme: light) {
          fill: var(--c-green-200);
        }
      }
    }
  }

  @media (max-width: 766px) {
    padding: 2rem;
    font-size: 1rem;

    header {
      padding-bottom: 3rem;
    }

    section {
      padding-bottom: 3rem;
    }
  }
`

const RichText = styled.div`
  padding-bottom: 3vw;
  p {
    padding-bottom: 1.5vw;
    line-height: 1.7;
  }

  b,
  strong {
    font-weight: bold;
  }

  ul {
    list-style: disc outside;
  }

  ol {
    list-style: decimal outside;
  }

  ul,
  ol {
    width: 90%;
    margin: 0 auto 2vw;
  }

  li {
    padding-bottom: 1.5vw;
  }
`

const Action = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const Close = styled.button`
  position: absolute;
  left: -2.5rem;
  top: 0;
  z-index: 200;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    object-fit: contain;
  }

  @media (max-width: 766px) {
    top: 1.8rem;
    left: 1.2rem;
  }

  @media (prefers-color-scheme: light) and (max-width: 766px) {
    path {
      fill: var(--c-blue-200);
    }
  }
`

const addUnderscores = text => {
  return text.replace(/ /g, "_")
}

const Ele = () => {
  const { modalContent, showModal } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()
  const closeModal = () => {
    dispatch({
      type: "SET_SHOW_MODAL",
      show: false,
      content: "",
    })

    let body = document.getElementsByTagName("body")[0]
    body.style.overflow = "visible"
  }

  React.useEffect(() => {
    if (showModal) {
      let body = document.getElementsByTagName("body")[0]
      body.style.overflow = "hidden"
    }
  }, [showModal])
  return (
    <Box className={"" + showModal}>
      <Wrap>
        <Close onClick={() => closeModal()}>
          <Icon title="close" fill="#fff" />
        </Close>

        <Content className="has-cta">
          <Pattern size="4" type="dots" />
          <header>
            {modalContent.title && <h2>{modalContent.title}</h2>}
            {modalContent.subtitle && (
              <div>
                <Icon title="time" />
                <span>{modalContent.subtitle}</span>
              </div>
            )}
          </header>

          {modalContent.objective && (
            <section>
              <p>{modalContent.objective}</p>
            </section>
          )}

          {modalContent.benefits && (
            <section>
              <h3>Best if</h3>
              <ul>
                {modalContent.benefits.map((ele, index) => {
                  return (
                    <li key={index}>
                      <Icon title="chevron-black" />
                      {ele}
                    </li>
                  )
                })}
              </ul>
            </section>
          )}

          {modalContent.elements && (
            <section>
              <h3>Key Considerations</h3>
              <ul>
                {modalContent.elements.map((ele, index) => {
                  return (
                    <li key={index}>
                      <Icon title="chevron-black" />
                      {ele}
                    </li>
                  )
                })}
              </ul>
            </section>
          )}

          {modalContent.html && (
            <RichText
              dangerouslySetInnerHTML={{
                __html: modalContent.html,
              }}
            ></RichText>
          )}

          {modalContent.cta && (
            <Action>
              <GetStarted
                to={
                  modalContent.cta
                    ? modalContent.cta
                    : "https://invest.myxeno.com"
                }
                className="dark"
                title="Create Profile"
                id={
                  addUnderscores(modalContent.title.toLowerCase()) +
                  "__create_profile_button"
                }
              />
            </Action>
          )}
        </Content>
      </Wrap>
    </Box>
  )
}
export default Ele
