import React from "react"
import Button from "./button"
import { logEvent } from "../../utilities/logevent"
const Ele = ({ title, to, id }) => {
  return (
    <>
      <Button
        id={id}
        label={title}
        click={() => {
          logEvent("Home", title)
        }}
        to={to ? to : "https://join.myxeno.com"}
      />
    </>
  )
}
export default Ele
